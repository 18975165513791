import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Card,
  CardContent,
  Divider,
  Grid,
} from "@material-ui/core";
import { useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import ChipComponent from "../../../components/ChipGeneratorFull";
import Skeleton from "@mui/lab/Skeleton";
import { BASE_FILE_URL } from "../../../constants/apiTags";
import { colorTags } from "../../../constants/variants";
import { images } from "../../../assets/index";
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "70%",
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      justifyContent: "center",
      alignItems: "flex-start",
    },
  },
  root: {
    margin: "20px auto",
    height: "auto",
    overflow: "hidden",
    display: "flex",
    flexDirection: "row",
    boxShadow: "none",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  media: {
    width: "45%",
    maxHeight: "260px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
    // marginTop: theme.spacing(2),

    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "200px",
    },
  },
  content: {
    width: "55%",
    // backgroundColor: "red",
    padding: theme.spacing(4),
    paddingLeft: "0px",
    paddingTop: "0px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",

    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: theme.spacing(2),
      paddingLeft: "0px",

      alignItems: "flex-start",
    },
  },
  title: {
    fontSize: "2.5rem",
    fontWeight: "500",
    fontFamily: "Times-new-roman",
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(0),

    paddingTop: "0px",

    color: "#222",
    transition: "color 0.3s ease",
    "&:hover": {
      // color: "#007bff",
      color: colorTags.TERTIARY,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.8rem",
    },
  },
  intro: {
    fontSize: "1.1rem",
    lineHeight: 1.4,
    color: "#555",
    transition: "color 0.3s ease",
    "&:hover": {
      color: "#0056b3", // Change to desired hover color
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  },
  overlay: {
    position: "absolute",
    bottom: "0px",
    right: "0px",
    color: "white",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    padding: "2px 5px",
    borderRadius: "0px",
  },
  divider: {
    marginTop: theme.spacing(3),
    width: "100%",
  },
  smallerStory: {
    marginTop: theme.spacing(3),
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    boxShadow: "none",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      paddingLeft: "0px",
      marginTop: theme.spacing(2),
    },
  },
  smallerMedia: {
    width: "40%",
    height: "100px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
      height: "120px",
    },
  },
  smallerContent: {
    width: "50%",
    height: "100px",
    padding: theme.spacing(2),
    paddingTop: "0px",
    paddingLeft: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    [theme.breakpoints.down("sm")]: {
      // width: "100%",
      width: "80%",

      paddingLeft: "0px",
      height: "100px",
      marginTop: "10px",
    },
  },
  smallerTitle: {
    fontSize: "1.21rem",
    lineHeight: 1.1,
    fontFamily: "Times-new-roman",
    fontWeight: "bold",
    marginTop: "0px",
    paddingTop: "0px",
    marginBottom: theme.spacing(1),
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    color: "#222",
    transition: "color 0.3s ease",
    "&:hover": {
      // color: "#007bff",
      color: colorTags.TERTIARY,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem",
    },
  },
  smallerIntro: {
    fontSize: "0.8rem",
    lineHeight: 1.2,
    color: "#555",
    transition: "color 0.3s ease",
    "&:hover": {
      // color: "#0056b3",
      color: colorTags.TERTIARY,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem",
    },
  },
}));

const truncateText = (text, maxLength) => {
  if (text?.length > maxLength) {
    return text?.substring(0, maxLength) + "...";
  }
  return text;
};

const TopStory = ({ mainArticle, smallerArticles }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width: 600px)");

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return (
      <div className={classes.container}>
        <Card className={classes.root} style={{ boxShadow: "none" }}>
          <CardContent className={classes.content}>
            <Skeleton variant="text" height={40} width={isMobile ? 350 : 450} />
            <Skeleton variant="text" height={20} width={isMobile ? 350 : 450} />
            <Skeleton variant="text" height={20} width={isMobile ? 350 : 450} />
            <Skeleton variant="text" height={20} width={isMobile ? 350 : 450} />
          </CardContent>
          <Skeleton
            variant="rectangular"
            width={isMobile ? 350 : 550}
            height={isMobile ? 120 : 200}
            className={classes.media}
          />
        </Card>
        <Divider className={classes.divider} />
        <Grid container spacing={isMobile ? 0 : 2}>
          {Array.from(new Array(4)).map((_, index) => (
            <Grid item xs={12} md={6} key={index}>
              <Card className={classes.smallerStory}>
                <Skeleton
                  variant="rectangular"
                  className={classes.smallerMedia}
                  width={isMobile ? 200 : 400}
                  height={isMobile ? 120 : 100}
                />
                <CardContent className={classes.smallerContent}>
                  <Skeleton
                    variant="text"
                    height={20}
                    width={isMobile ? 200 : 400}
                  />
                  <Skeleton
                    variant="text"
                    height={20}
                    width={isMobile ? 200 : 400}
                  />
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <Card className={classes.root} style={{ boxShadow: "none" }}>
        <CardContent
          className={classes.content}
          style={{
            paddingTop: "0px",
          }}>
          <Typography variant="h3" className={classes.title}>
            <Link
              to={`/news-detail/${smallerArticles && smallerArticles[0]?.id}`}>
              {" "}
              {truncateText(smallerArticles && smallerArticles[0]?.title, 80)}
            </Link>
          </Typography>
          <Typography variant="body1" className={classes.intro}>
            {truncateText(
              smallerArticles && smallerArticles[0]?.paragraph1,
              250
            )}
          </Typography>
        </CardContent>
        <div
          className={classes.media}
          style={{
            // backgroundImage: `url(${BASE_FILE_URL}${
            //   smallerArticles && smallerArticles[0]?.coverImage
            // })`,
            backgroundImage:
              //  `url(${BASE_FILE_URL}${article?.coverImage})`,
              smallerArticles &&
              smallerArticles[0]?.coverImage?.coverImage !== null
                ? `url(${BASE_FILE_URL}${smallerArticles[0]?.coverImage})`
                : `url(${images.NEWS_PLACEHOLDER}`,
            // backgroundImage: `url(${images.CategoryNewsFullNative6})`,
          }}>
          <div className={classes.overlay}>
            <Typography variant="div">
              {smallerArticles && smallerArticles[0]?.subtitle}
            </Typography>
          </div>
          <div style={{ marginLeft: "0px" }}>
            <ChipComponent
              category={smallerArticles && smallerArticles[0]?.generalCategory}
            />
          </div>
        </div>
      </Card>
      <Divider className={classes.divider} />
      <Grid container spacing={isMobile ? 0 : 2}>
        {smallerArticles?.slice(1, 5).map((article, index) => (
          <Grid item xs={12} md={6} key={index}>
            <Link to={`/news-detail/${article && article?.id}`}>
              <Card className={classes.smallerStory}>
                <div
                  className={classes.smallerMedia}
                  style={{
                    // backgroundImage: `url(${BASE_FILE_URL}${article?.coverImage})`,
                    backgroundImage:
                      //  `url(${BASE_FILE_URL}${article?.coverImage})`,
                      article?.coverImage !== null
                        ? `url(${BASE_FILE_URL}${article?.coverImage})`
                        : `url(${images.NEWS_PLACEHOLDER}`,
                  }}>
                  <div style={{ marginLeft: "0px" }}>
                    <ChipComponent category={article?.generalCategory} />
                  </div>
                </div>

                <CardContent
                  className={classes.smallerContent}
                  style={{
                    paddingTop: "0px",
                  }}>
                  <Typography variant="h6" className={classes.smallerTitle}>
                    {article?.title}
                  </Typography>
                  <Typography variant="body2" className={classes.smallerIntro}>
                    {truncateText(article?.paragraph1, 100)}
                  </Typography>
                </CardContent>
              </Card>
            </Link>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default TopStory;
