import React, { useState, useEffect } from "react";
import {
  Button,
  Grid,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Stack,
  Typography,
  TablePagination,
} from "@mui/material";
import {
  GetApp as GetAppIcon,
  Search as SearchIcon,
} from "@mui/icons-material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { CSVLink } from "react-csv";
import { useGetLogsQuery } from "../../../features/api/apiSlice";
import { Bars } from "react-loader-spinner";
import { colorTags } from "../../../constants/variants";
import format from "date-fns/format"; // To format date

const LogsPage = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [username, setUsername] = useState("");
  const [action, setAction] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [token, setToken] = useState(localStorage.getItem("authToken"));
  const [filteredLogs, setFilteredLogs] = useState([]);

  const {
    data: logsData,
    isLoading: isLoadingLogsData,
    isError: isErrorLogsData,
    error: errorLogsData,
  } = useGetLogsQuery();

  const exportFileName = "logs";

  useEffect(() => {
    if (logsData) setFilteredLogs(logsData);
  }, [logsData]);
  const handleInputChange = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchQuery(value);

    const filtered = logsData?.filter((log) => {
      const username = log.username.toLowerCase();
      const action = log.action.toLowerCase();
      const date = log.date;

      return (
        username.includes(value) ||
        action.includes(value) ||
        date.includes(value)
      );
    });

    setFilteredLogs(filtered);
  };

  const handleSearch = () => {
    let filtered = logsData ?? [];

    if (username) {
      const lowercaseUsername = username.toLowerCase();
      filtered = filtered.filter((log) =>
        log.username.toLowerCase().includes(lowercaseUsername)
      );
    }

    if (action) {
      const lowercaseAction = action.toLowerCase();
      filtered = filtered.filter((log) =>
        log.action.toLowerCase().includes(lowercaseAction)
      );
    }

    if (startDate && endDate) {
      const formattedStartDate = format(startDate, "yyyy-MM-dd");
      const formattedEndDate = format(endDate, "yyyy-MM-dd");
      filtered = filtered.filter(
        (log) =>
          log.date.slice(0, 10) >= formattedStartDate &&
          log.date.slice(0, 10) <= formattedEndDate
      );
    }

    setFilteredLogs(filtered);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (isLoadingLogsData)
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Bars
          height="40"
          width="40"
          color={colorTags.TERTIARY}
          ariaLabel="bars-loading"
          visible={true}
        />
      </div>
    );

  return (
    <div style={{ margin: "5%" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
          backgroundColor: "white",
        }}>
        <Stack
          direction="column"
          spacing={3}
          justifyContent="center"
          style={{
            width: "50%",
            padding: "2% 10%",
            boxShadow:
              "rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset",
          }}>
          <TextField
            fullWidth
            name="username"
            label="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            fullWidth
            name="action"
            label="Action"
            value={action}
            onChange={(e) => setAction(e.target.value)}
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Stack spacing={3} justifyContent={"flex-end"} direction="row">
              <DesktopDatePicker
                inputFormat="dd/MM/yyyy"
                value={startDate}
                onChange={(date) => setStartDate(date)}
                renderInput={(params) => (
                  <TextField
                    variant="outlined"
                    {...params}
                    label="Start Date"
                  />
                )}
              />
              <DesktopDatePicker
                inputFormat="dd/MM/yyyy"
                value={endDate}
                onChange={(date) => setEndDate(date)}
                renderInput={(params) => (
                  <TextField variant="outlined" {...params} label="End Date" />
                )}
              />
            </Stack>
          </LocalizationProvider>
        </Stack>
      </div>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
          <Stack direction="row" mt={3} alignItems="center" spacing={3}>
            <Button
              variant="contained"
              color="success"
              style={{ backgroundColor: colorTags.TERTIARY }}
              onClick={handleSearch}>
              Search
            </Button>
            <Button
              variant="outlined"
              startIcon={<GetAppIcon />}
              style={{ marginLeft: "10px", color: colorTags.TERTIARY }}>
              <CSVLink
                data={filteredLogs ?? []}
                filename={exportFileName + ".csv"}
                style={{ textDecoration: "none", color: "inherit" }}>
                Export CSV
              </CSVLink>
            </Button>
          </Stack>
        </Grid>
      </Grid>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "4%",
        }}>
        <TextField
          name="search"
          label="Search"
          variant="outlined"
          color="success"
          InputProps={{
            startAdornment: <SearchIcon />,
          }}
          value={searchQuery}
          onChange={handleInputChange}
        />
      </div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Username</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredLogs
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((log) => (
                <TableRow key={log.id}>
                  <TableCell>{log.username}</TableCell>
                  <TableCell>
                    {format(new Date(log.date), "dd/MM/yyyy HH:mm:ss")}
                  </TableCell>
                  <TableCell>{log.action}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredLogs?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};

export default LogsPage;
