import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Divider,
  CardContent,
  Card,
  Grid,
  Chip,
} from "@material-ui/core";

import { colorTags } from "../../../constants/variants";
import { images } from "../../../assets/index";
import { useTranslation } from "react-i18next";
import ChipComponent from "../../../components/ChipGeneratorFull";
import { BASE_FILE_URL } from "../../../constants/apiTags";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "95%",
    margin: "0 auto",
  },
  divider: {
    marginTop: theme.spacing(3),
    // backgroundColor: "#272829",
    backgroundColor: colorTags.TERTIARY,

    width: "100%",
  },
  categoryTitle: {
    width: "100%",
    display: "flex",
    color: colorTags.PRIMARY,

    justifyContent: "flex-start",
    alignItems: "flex-start",
    margin: theme.spacing(2, 0),
    lineHeight: 1.2,
    fontSize: "1.5rem",
    fontWeight: "bold",
  },
  smallerStory: {
    position: "relative",
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    boxShadow: "none",
    "&:hover": {
      // color: "#007bff",
      color: colorTags.TERTIARY,
    },
    [theme.breakpoints.down("sm")]: {
      alignItems: "flex-start",
      marginTop: theme.spacing(0),
    },
  },
  smallerMedia: {
    width: "90%",
    height: "130px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  smallerContent: {
    width: "90%",
    padding: theme.spacing(2),
    paddingTop: "0px",
    textAlign: "left",
  },
  smallerTitle: {
    fontSize: "1rem",
    fontFamily: "Times-new-roman",
    fontWeight: "bold",
    marginTop: "10px",
    lineHeight: "1.2rem",
    color: "#222",
    "&:hover": {
      // color: "#007bff",
      color: colorTags.TERTIARY,
    },
  },
  smallerIntro: {
    fontSize: "0.9rem",
    lineHeight: 1.2,
    color: "#555",
  },
  date: {
    position: "absolute",
    bottom: theme.spacing(0),
    right: theme.spacing(0),
    color: "#fff",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    padding: "2px 5px",
    borderRadius: "3px",
    fontSize: "0.8rem",
  },
}));
const truncateText = (text, maxLength) => {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + "...";
  }
  return text;
};
const CategoryNews = ({ category, articles, category2, articles2 }) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const currentLanguage = i18n.language;
  return (
    <div className={classes.container}>
      <Divider className={classes.divider} />
      <Typography className={classes.categoryTitle}>{t(category)}</Typography>
      <Grid container spacing={5}>
        {articles?.slice(0, 4).map((article, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Link to={`/news-detail/${article && article?.id}`}>
              <Card className={classes.smallerStory}>
                <div
                  className={classes.smallerMedia}
                  style={{
                    backgroundImage:
                      //  `url(${BASE_FILE_URL}${article?.coverImage})`,
                      article?.coverImage !== null
                        ? `url(${BASE_FILE_URL}${article?.coverImage})`
                        : `url(${images.NEWS_PLACEHOLDER}`,
                  }}>
                  <div className={classes.date}>{article?.date}</div>
                  <div style={{ marginLeft: "0px" }}>
                    <ChipComponent category={article?.generalCategory} />
                  </div>
                </div>
                <CardContent
                  className={classes.smallerContent}
                  style={{
                    paddingLeft: "0px",
                  }}>
                  <Typography variant="h6" className={classes.smallerTitle}>
                    {truncateText(article?.title, 100)}
                  </Typography>
                  {/* <Typography variant="body2" className={classes.smallerIntro}>
                  {truncateText(article.intro, 50)}
                </Typography> */}
                </CardContent>
              </Card>
            </Link>
          </Grid>
        ))}
      </Grid>
      <Divider className={classes.divider} />
      <Typography className={classes.categoryTitle}>{t(category2)}</Typography>
      <Grid container spacing={4}>
        {articles2?.slice(0, 4).map((article, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Link to={`/news-detail/${article && article?.id}`}>
              <Card className={classes.smallerStory}>
                <div
                  className={classes.smallerMedia}
                  style={{
                    // backgroundImage: `url(${BASE_FILE_URL}${article?.coverImage})`,
                    backgroundImage:
                      //  `url(${BASE_FILE_URL}${article?.coverImage})`,
                      article?.coverImage !== null
                        ? `url(${BASE_FILE_URL}${article?.coverImage})`
                        : `url(${images.NEWS_PLACEHOLDER}`,
                  }}>
                  <div className={classes.date}>{article?.date}</div>
                  <div style={{ marginLeft: "0px" }}>
                    <ChipComponent category={article?.generalCategory} />
                  </div>
                </div>
                <CardContent
                  className={classes.smallerContent}
                  style={{
                    paddingLeft: "0px",
                  }}>
                  <Typography variant="h6" className={classes.smallerTitle}>
                    {truncateText(article?.title, 100)}
                  </Typography>
                  {/* <Typography variant="body2" className={classes.smallerIntro}>
                  {truncateText(article.intro, 50)}
                </Typography> */}
                </CardContent>
              </Card>
            </Link>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default CategoryNews;
