import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  CardContent,
  Card,
  Divider,
  Grid,
  Button,
} from "@material-ui/core";
import { colorTags } from "../../../../constants/variants";
import { useTranslation } from "react-i18next";
import ChipComponent from "../../../../components/ChipGeneratorFull";
import { BASE_FILE_URL } from "../../../../constants/apiTags";
import { Link } from "react-router-dom";
import { images } from "../../../../assets";
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "95%",
    margin: "0 auto",
  },
  divider: {
    marginTop: theme.spacing(3),
    backgroundColor: colorTags.TERTIARY,
    width: "100%",
  },
  categoryTitle: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    margin: theme.spacing(2, 0),
    lineHeight: 1.2,
    fontSize: "1.5rem",
    fontWeight: "bold",
    color: colorTags.PRIMARY,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem",
      textAlign: "center",
    },
  },
  smallerStory: {
    position: "relative",
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    boxShadow: "none",
  },
  smallerMedia: {
    width: "100%",
    height: "100px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
  },
  smallerContent: {
    width: "100%",
    height: "100px",

    padding: theme.spacing(2),
    paddingTop: "0px",
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
    },
  },
  smallerTitle: {
    fontSize: "1rem",
    fontFamily: "Times-new-roman",
    fontWeight: "bold",
    marginTop: "10px",
    textAlign: "left",
    lineHeight: "1.1rem",
    color: "#222",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.9rem",
    },
    "&:hover": {
      // color: "#007bff",
      color: colorTags.TERTIARY,
    },
  },
  smallerIntro: {
    fontSize: "0.9rem",
    lineHeight: 1.2,
    color: "#555",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem",
    },
  },
  date: {
    position: "absolute",
    bottom: theme.spacing(1),
    right: theme.spacing(0),
    color: "#333",
    // backgroundColor: "rgba(0, 0, 0, 0.6)",
    padding: "2px 5px",
    borderRadius: "3px",
    fontSize: "0.8rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.7rem",
    },
  },
  loadMoreButton: {
    marginTop: theme.spacing(2),
  },
}));

const CategoryNews = ({ category, articles, category2, articles2 }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [visibleCount, setVisibleCount] = useState(8);

  const handleLoadMore = () => {
    setVisibleCount((prevCount) => prevCount + 8);
  };
  const handleLoadLess = () => {
    setVisibleCount(8);
  };

  const truncateText = (text, limit) => {
    return text.length > limit ? text.substring(0, limit) + "..." : text;
  };

  return (
    <div className={classes.container}>
      <Divider className={classes.divider} />
      <Typography className={classes.categoryTitle}>{category2}</Typography>
      <Grid container spacing={2}>
        {articles2?.slice(0, 8).map((article, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Card className={classes.smallerStory}>
              <div
                className={classes.smallerMedia}
                // style={{ backgroundImage: `url(${article.image})` }}
                style={{
                  backgroundImage:
                    article.coverImage !== null
                      ? `url(${BASE_FILE_URL + article.coverImage})`
                      : `url(${images.NewsArticlePlaceholder})`,
                }}>
                <div style={{ marginLeft: "0px" }}>
                  {/* <ChipComponent category={article?.generalCategory} /> */}
                  <ChipComponent
                    // category={article?.generalCategory}
                    category={
                      article &&
                      new Date(article?.createdAt).toLocaleString("en-us")
                    }
                  />
                </div>
              </div>
              <CardContent
                className={classes.smallerContent}
                style={{
                  paddingLeft: "0px",
                }}>
                <Link to={`/news-detail/${article && article?.id}`}>
                  <Typography variant="h6" className={classes.smallerTitle}>
                    {article
                      ? truncateText(article.title, 50)
                      : "No articles found"}
                  </Typography>
                  <div className={classes.date}>{article.date}</div>
                </Link>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Divider className={classes.divider} />
      <Typography className={classes.categoryTitle}>{t(category)}</Typography>
      <Grid container spacing={2}>
        {articles.slice(0, visibleCount).map((article, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Card className={classes.smallerStory}>
              <div
                className={classes.smallerMedia}
                // style={{ backgroundImage: `url(${article.image})` }}
                style={{
                  backgroundImage:
                    article.coverImage != null
                      ? `url(${BASE_FILE_URL + article.coverImage})`
                      : `url(${images.NewsArticlePlaceholder})`,
                }}>
                <div style={{ marginLeft: "0px" }}>
                  {/* <ChipComponent category={article.category} /> */}
                  <ChipComponent
                    // category={article?.generalCategory}
                    category={
                      article &&
                      new Date(article?.createdAt).toLocaleString("en-us")
                    }
                  />
                </div>
              </div>
              <CardContent
                className={classes.smallerContent}
                style={{
                  paddingLeft: "0px",
                }}>
                <Link to={`/news-detail/${article && article?.id}`}>
                  <Typography variant="h6" className={classes.smallerTitle}>
                    {truncateText(article.title, 50)}
                  </Typography>
                  <div className={classes.date}></div>
                </Link>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      {visibleCount < articles.length && (
        <Button
          variant="text"
          // color="primary"
          style={{
            color: colorTags.TERTIARY,
          }}
          className={classes.loadMoreButton}
          onClick={handleLoadMore}>
          {t("Load More")}
        </Button>
      )}
      {visibleCount === articles.length && (
        <Button
          variant="text"
          // color="primary"
          style={{
            color: colorTags.TERTIARY,
          }}
          className={classes.loadMoreButton}
          onClick={handleLoadLess}>
          {t("Load Less")}
        </Button>
      )}
    </div>
  );
};

export default CategoryNews;
