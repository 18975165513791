import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import NavBar from "../../Header/Header2";
import LogoHead from "../../Header/LogoHead2";
import Title from "../../Header/Title2";
import { images } from "../../../../assets";
import {
  Stack,
  Grid,
  Card,
  CardContent,
  Typography,
  Box,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import TrendingNews from "./TrendingNewsInCategory";
import MostReadThisMonth from "./MostReadThisMonthInCategory";
import CategoryNews from "./CategoryNewsLines";
import Advertisements from "../Advertisements";
import Footer from "../../Footer/Footer2";
import { colorTags } from "../../../../constants/variants";
import {
  useGetArticlesCategoryLatestQuery,
  useGetAdsQuery,
  useGetCategoriesQuery,
} from "../../../../features/api/apiSlice";
import { useTranslation } from "react-i18next";
import { BASE_FILE_URL } from "../../../../constants/apiTags";

const CategoryTitlePage = () => {
  const [currentCategoryIndex, setCurrentCategoryIndex] = useState(0);
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { title } = useParams();
  const navigate = useNavigate();
  // const validCategories = Object.keys(categoryData);

  // if (!validCategories.includes(title)) {
  //   navigate("/404");
  //   return null;
  // }
  const capitalizeFirstLetter = (string) => {
    return string?.charAt(0).toUpperCase() + string?.slice(1);
  };
  const {
    data: categoryNewsData,
    isLoading: isLoadingCategoryNews,
    isError: isErrorCategoryNews,
    error: categoryNewsError,
  } = useGetArticlesCategoryLatestQuery({
    title: capitalizeFirstLetter(title),
    lang: "en",
    amount: 36,
  });

  const {
    data: categoryNewsAmData,
    isLoading: isLoadingCategoryNewsAm,
    isError: isErrorCategoryNewsAm,
    error: categoryNewsAmError,
  } = useGetArticlesCategoryLatestQuery({
    title: capitalizeFirstLetter(title),
    lang: "am",
    amount: 36,
  });

  const categories = Object.values(
    currentLanguage === "am" ? categoryNewsAmData ?? [] : categoryNewsData ?? []
  );
  const categoryInfo =
    currentLanguage === "am"
      ? categoryNewsAmData && categoryNewsAmData[currentCategoryIndex]
      : categoryNewsData && categoryNewsData[currentCategoryIndex];

  const {
    data: adsData,
    isLoading: isLoadingAds,
    isError: isErrorAds,
    error: adsError,
  } = useGetAdsQuery();

  const truncateText = (text, maxLength) => {
    if (text?.length <= maxLength) {
      return text;
    }
    return `${text?.substring(0, maxLength)}...`;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentCategoryIndex((prevIndex) =>
        prevIndex === categories.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000); // Change the slide every 5 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [categories.length]);

  const styles = {
    container: {
      width: "100%",
      maxWidth: "1200px",
      margin: "0 auto",
      padding: isMobile ? "10px" : "15px 0px",
      fontFamily: "Arial, sans-serif",
    },
    header: {
      position: "relative",
      marginLeft: isMobile ? "0%" : "30%",
      width: isMobile ? "100%" : "70%",
      height: isMobile ? "150px" : "250px",
      backgroundSize: "cover",
      backgroundPosition: "center",
      borderRadius: isMobile ? "20px" : "40px",
      textAlign: "center",
      padding: "20px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderBottom: "2px solid #ddd",
      marginBottom: isMobile ? "110px" : "50px",
    },
    smallerHeader: {
      height: "150px",
      width: isMobile ? "calc(100% - 40px)" : "300px",
      background: "rgba(255, 255, 255, 0.5)",
      backdropFilter: "blur(10px)",
      color: "#000",
      position: "absolute",
      bottom: isMobile ? "-100px" : "-40px",
      left: isMobile ? "20px" : "-150px",
      borderRadius: "30px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      padding: "20px",
      cursor: "pointer",

      paddingBottom: "30px",

      boxShadow: `rgba(${colorTags.PRIMARY_RGBA}, 0.2) 0px 7px 29px 0px`,
    },
    smallTitle: {
      fontSize: isMobile ? "18px" : "21px",
      fontWeight: 500,
      fontFamily: "Times-new-roman",
      textAlign: "left",
    },
    title: {
      fontSize: isMobile ? "36px" : "56px",
      fontWeight: 500,
      margin: "0",
      color: "#fff",
    },
    intro: {
      fontSize: "16px",
      marginTop: "10px",
      textAlign: "left",
    },
    date: {
      fontSize: "14px",
      marginTop: "10px",
      textAlign: "center",
      color: "#555",
      position: "absolute",
      bottom: "10px",
      left: "30px",
    },
    content: {
      marginTop: "20px",
    },
    card: {
      minHeight: "200px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      border: "1px solid #ddd",
      borderRadius: "8px",
    },
    cardContent: {
      padding: "20px",
    },
    cardTitle: {
      fontSize: "24px",
      fontWeight: "bold",
      margin: "0 0 10px 0",
    },
    cardDescription: {
      fontSize: "16px",
      margin: "0",
    },
  };

  if (!categoryNewsAmData && !categoryNewsData)
    return (
      <>
        <Title />
        <LogoHead />
        <NavBar />
        <div style={{ height: "100vh", margin: "0 auto" }}>
          <h1>No Data Found</h1>
        </div>
      </>
    );
  else
    return (
      <>
        <Title />
        <LogoHead />
        <NavBar />
        <div style={styles.container}>
          <Stack direction={isMobile ? "column" : "row"} spacing={3} mt={2}>
            <Box sx={{ flex: 4 }}>
              <div
                style={{
                  ...styles.header,
                  // backgroundImage: `url(${categoryInfo?.image})`,
                  // backgroundImage: `url(${categoryInfo?.image})`,
                  backgroundImage: `url(${
                    BASE_FILE_URL + categoryInfo?.coverImage
                  })`,

                  cursor: "pointer",
                }}
                onClick={() => navigate(`/news-detail/${categoryInfo?.id}`)}>
                <div
                  style={styles.smallerHeader}
                  onClick={() => navigate(`/news-detail/${categoryInfo?.id}`)}>
                  <h3 style={styles.smallTitle}>
                    {categoryInfo
                      ? truncateText(categoryInfo?.title, 65)
                      : "No Articles Found"}
                  </h3>
                  <p style={styles.date}>{categoryInfo?.date}</p>
                </div>
              </div>
              {isMobile && (
                <Stack sx={{ flex: 1 }}>
                  <MostReadThisMonth category={capitalizeFirstLetter(title)} />
                  <div style={{ marginTop: "15px" }}></div>

                  {/* <Advertisements position="sidebar" items={sidebarAds} /> */}
                  <Advertisements
                    position="sidebar"
                    items={adsData && adsData}
                  />
                </Stack>
              )}
              <CategoryNews
                category={"More"}
                // articles={newsItems2}
                articles={
                  currentLanguage === "am"
                    ? categoryNewsAmData?.slice(8, 36) ?? []
                    : categoryNewsData?.slice(8, 36) ?? []
                }
                category2="Recent"
                // articles2={newsItems}
                articles2={
                  currentLanguage === "am"
                    ? categoryNewsAmData ?? []
                    : categoryNewsData ?? []
                }
              />
              {isMobile && (
                // <Advertisements position="sidebar" items={sidebarAds} />
                // <Advertisements position="sidebar" items={adsData && adsData} />
                <Advertisements position="header" items={adsData && adsData} />
              )}
            </Box>
            {!isMobile && (
              <Stack sx={{ flex: 1 }}>
                {/* <Advertisements position="sidebar" items={sidebarAds} /> */}
                {/* <Advertisements position="sidebar" items={adsData && adsData} /> */}
                <Advertisements position="header" items={adsData} />

                <div style={{ marginTop: "15px" }}></div>
                <MostReadThisMonth
                  category={`${capitalizeFirstLetter(title)}`}
                />
              </Stack>
            )}
          </Stack>
        </div>
        <Footer />
      </>
    );
};

export default CategoryTitlePage;
