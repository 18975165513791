import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, Chip, Grid, Typography, Link } from "@material-ui/core";
import {
  Facebook,
  YouTube,
  Telegram,
  Twitter,
  LinkedIn,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { colorTags } from "../../../constants/variants";

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: `rgba(${colorTags.PRIMARY_RGBA},0.45)`,
    backgroundSize: "cover",
    backgroundPosition: "center",

    position: "relative",
    backdropFilter: "blur(10px)",
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(15),
    color: "#fff",
  },
  overlay: {
    content: '""',
    width: "100%",
    height: "100%",
    backdropFilter: "blur(10px)",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: -1,
    background: "rgba(0, 0, 0, 0.0)",
  },
  tagCard: {
    width: "100%",
    height: "80px",
    // borderTop: `1px solid ${colorTags.TERTIARY}`,
    borderRadius: "0px",
    display: "flex",
    // display: "none",

    alignItems: "center",
    justifyContent: "flex-start",
    paddingLeft: theme.spacing(5),
    marginBottom: theme.spacing(0),
    marginTop: "20px",

    boxShadow: "rgba(0, 0, 0, 0.56) 0px 22px 70px 4px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  chip: {
    margin: theme.spacing(0.5),
    border: `1px solid ${colorTags.TERTIARY}`,
    color: "black",
    backgroundColor: "white",
    fontStyle: "italic",
  },
  socialCard: {
    height: "80px",
    width: "50%",
    boxShadow: "rgba(0, 0, 0, 0.36) 0px 22px 70px 4px",

    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    margin: theme.spacing(0, "auto", 3),
    marginTop: "-40px",
    marginBottom: "0px",
    zIndex: 1,
    position: "relative",
    backgroundColor: `rgba(${colorTags.PRIMARY_RGBA},1)`,
    borderRadius: "8px",
    padding: theme.spacing(0, 2),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "auto",
      marginTop: "-10px",

      flexDirection: "column",
      padding: theme.spacing(2),
    },
  },
  socialIcon: {
    color: "#fff",
    margin: theme.spacing(0, 1),
    cursor: "pointer",
    transition: "color 0.3s",
    "&:hover": {
      color: `${colorTags.SECONDARY}`,
    },
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(1, 0),
    },
  },
  socialIconYoutube: {
    color: "#fff",
    margin: theme.spacing(0, 1),
    cursor: "pointer",
    transition: "color 0.3s",
    "&:hover": {
      color: "#ff0000",
    },
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(1, 0),
    },
  },
  contactInfo: {
    color: "#fff",
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(2),
      textAlign: "center",
    },
  },
  miniFooter: {
    backgroundColor: `rgba(${colorTags.PRIMARY_RGBA},0.85)`,
    boxShadow: "rgba(0, 0, 0, 0.46) 0px 22px 70px 4px",

    padding: theme.spacing(1),
    color: "#fff",
    height: "80px",
    marginTop: "-40px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    textAlign: "center",
    borderRadius: "0px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      height: "auto",
      marginTop: "-10px",

      padding: theme.spacing(2),
      alignItems: "center",
    },
  },
  links: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  link: {
    margin: theme.spacing(0, 1),
    color: "#000",
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(1, 0),
    },
  },
  aboutUsText: {
    fontSize: "1.1rem",
  },
  contentLinksList: {
    fontSize: "1.23rem",
  },
  company: {
    fontSize: "1.5rem",
  },
  gridsContainer: {
    display: "flex",
    justifyContent: "center",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  aboutContainer: {
    position: "absolute",
    top: 10,
    left: 10,
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      top: 10,
      // left: 10,
    },
  },
}));

const Footer = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Card className={classes.tagCard}>
        {/* {["#Economy", "#AI", "#Election2024", "#Ethiopia", "#Industry"].map(
          (tag, index) => (
            <Chip key={index} label={tag} className={classes.chip} />
          )
        )} */}
        <Typography variant="h6" className={classes.company}>
          {t("Addis Chamber News")}
        </Typography>
      </Card>

      <footer className={classes.footer}>
        <div className={classes.overlay}></div>
        <Grid
          container
          spacing={3}
          style={{}}
          className={classes.gridsContainer}>
          <Grid item xs={10} sm={4} md={2} className={classes.aboutContainer}>
            <Typography variant="h6">{t("About Us")}</Typography>

            <Typography variant="body2" className={classes.aboutUsText}>
              {t(
                "ACBN (Addis Chamber Business News) is a media platform brought to you by Addis Chamber. Our aim is to provide business news and analysis that contextualizes how these issues affect businesses."
              )}
            </Typography>
          </Grid>

          <Grid
            item
            xs={10}
            sm={4}
            md={2}
            // style={{ borderLeft: "1px solid #343A40" }}
          >
            <Typography variant="h5"> {t("Editorial")}</Typography>
            <ul className={classes.contentLinksList}>
              <li>
                <Link href="/news-category/economy" color="inherit">
                  {t("Economy")}
                </Link>
                <ul className={classes.contentLinksList}>
                  <li>
                    <Link href="/news-category/agriculture" color="inherit">
                      {t("Agriculture")}
                    </Link>
                  </li>
                  <li>
                    <Link href="/news-category/industry" color="inherit">
                      {t("Industry")}
                    </Link>
                  </li>
                  <li>
                    <Link href="/news-category/service" color="inherit">
                      {t("Service")}
                    </Link>
                  </li>
                  <li>
                    {/* <Link href="/news-category/green-economy" color="inherit">
                      {t("Green Economy")}
                    </Link>
                    <ul className={classes.contentLinksList}>
                      <li>
                        <Link
                          href="/news-category/climate-change"
                          color="inherit">
                          {t("Climate Change")}
                        </Link>
                      </li>
                      <li>
                        <Link
                          href="/news-category/technology-impact"
                          color="inherit">
                          {t("Technology")}
                        </Link>
                      </li>
                      <li>
                        <Link
                          href="/news-category/inclusive-economies"
                          color="inherit">
                          {t("Inclusive Economies")}
                        </Link>
                      </li>
                    </ul> */}
                  </li>
                </ul>
              </li>
            </ul>
          </Grid>

          <Grid
            item
            xs={10}
            sm={4}
            md={2}
            style={{ borderLeft: "1px solid #597445" }}>
            <Typography variant="h5">{t("Business News")}</Typography>
            <ul className={classes.contentLinksList}>
              <li>
                <Link href="/news-category/entrepreneurship" color="inherit">
                  {t("Entrepreneurship")}
                </Link>
              </li>
              {/* <li>
                <Link href="/news-category/wholesale-retail" color="inherit">
                  {t("Wholesale and Retail Trade")}
                </Link>
              </li> */}
              <li>
                <Link href="/news-category/export-import" color="inherit">
                  {t("Export-Import")}
                </Link>
              </li>
              {/* <li>
                <Link href="/news-category/transport-logistics" color="inherit">
                  {t("Transport and Logistics")}
                </Link>
              </li>
              <li>
                <Link href="/news-category/agribusiness" color="inherit">
                  {t("Agribusiness")}
                </Link>
              </li> */}
              <li>
                <Link href="/news-category/manufacturing" color="inherit">
                  {t("Manufacturing")}
                </Link>
              </li>
              {/* <li>
                <Link href="/news-category/construction" color="inherit">
                  {t("Construction")}
                </Link>
              </li> */}
              <li>
                <Link href="/news-category/banking-finance" color="inherit">
                  {t("Banking and Finance")}
                </Link>
              </li>
              {/* <li>
                <Link href="/news-category/tourism-hospitality" color="inherit">
                  {t("Tourism and Hospitality")}
                </Link>
              </li>
              <li>
                <Link href="/news-category/companies" color="inherit">
                  {t("Companies")}
                </Link>
              </li>
              <li>
                <Link href="/news-category/education" color="inherit">
                  {t("Education")}
                </Link>
              </li>
              <li>
                <Link href="/news-category/energy" color="inherit">
                  {t("Energy")}
                </Link>
              </li>
              <li>
                <Link
                  href="/news-category/fisheries-aquaculture"
                  color="inherit">
                  {t("Fisheries and Aquaculture")}
                </Link>
              </li>
              <li>
                <Link href="/news-category/health" color="inherit">
                  {t("Health")}
                </Link>
              </li>
              <li>
                <Link href="/news-category/insurance" color="inherit">
                  {t("Insurance")}
                </Link>
              </li> */}
            </ul>
          </Grid>

          {/* <Grid
            item
            xs={10}
            sm={4}
            md={2}
            style={{ borderLeft: "1px solid #597445" }}>
            <Typography variant="h6">{t("Features and Advocacy")}</Typography>
            <ul className={classes.contentLinksList}>
              <li>
                <Link href="/news-category/opinions" color="inherit">
                  {t("Opinions")}
                </Link>
              </li>
              <li>
                <Link href="/news-category/reports" color="inherit">
                  {t("Reports")}
                </Link>
              </li>
              <li>
                <Link href="/news-category/addis-chamber-tv" color="inherit">
                  {t("Addis Chamber TV")}
                </Link>
              </li>
              <li>
                <Link
                  href="/news-category/addis-chamber-podcast"
                  color="inherit">
                  {t("Addis Chamber Podcast")}
                </Link>
              </li>
              <li>
                <Link href="/news-category/events" color="inherit">
                  {t("Events")}
                </Link>
              </li>
            </ul>
          </Grid> */}
        </Grid>
      </footer>
      <Card className={classes.socialCard}>
        <div>
          <a
            href="https://www.facebook.com/addischamber/"
            target="_blank"
            rel="noopener noreferrer">
            <Facebook className={classes.socialIcon} />
          </a>
          <a
            href="https://www.youtube.com/addischamber"
            target="_blank"
            rel="noopener noreferrer">
            <YouTube className={classes.socialIconYoutube} />
          </a>
          <a
            href="https://t.me/addis_chamber"
            target="_blank"
            rel="noopener noreferrer">
            <Telegram className={classes.socialIcon} />
          </a>
          <a
            href="https://twitter.com/aaccsa?lang=en"
            target="_blank"
            rel="noopener noreferrer">
            <Twitter className={classes.socialIcon} />
          </a>
          <a
            href="https://et.linkedin.com/company/addischamber"
            target="_blank"
            rel="noopener noreferrer">
            <LinkedIn className={classes.socialIcon} />
          </a>
        </div>
        <div className={classes.contactInfo}>
          <Typography variant="body2">
            {/* {t("Email")}: info@acbn-digital.com */}
            <a
              href="info@addischamber.com"
              aria-label="Email"
              style={{ marginRight: "10px" }}>
              {t("Email")}: info@addischamber.com
            </a>
          </Typography>
          <Typography variant="body2">
            <a
              href="tel:+251115518055"
              aria-label="Call"
              style={{ marginRight: "10px" }}>
              {t("Phone")}: +251 115 518055
            </a>
          </Typography>
        </div>
      </Card>
      <Card className={classes.miniFooter}>
        <div className={classes.links}>
          <Typography className={classes.link}>
            {/* {t("Privacy Policy")} */}
            {t("Terms Apply")}
          </Typography>
          <Link href="/contact-us" color="inherit" className={classes.link}>
            {t("Contact")}
          </Link>
          <Link href="/login" color="inherit" className={classes.link}>
            {t("Log in")}
          </Link>
        </div>
        <div>
          <Typography variant="body2" textAlign="center">
            <Link
              href="https://addischamber.com/"
              target="_blank"
              color="inherit">
              Addis Ababa Chamber of Commerce and Sectoral Associations
            </Link>
          </Typography>
        </div>

        <div>
          <Typography variant="body2" style={{ color: colorTags.ACCENT }}>
            <Link href="http://www.arcict.net" target="_blank" color="inherit">
              © AR C ICT Technologies
            </Link>
          </Typography>
        </div>
      </Card>
    </>
  );
};

export default Footer;
