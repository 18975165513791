import React from 'react';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import { FormControl, Select, MenuItem, Tooltip } from '@mui/material';
import { FlagIcon } from 'react-flag-kit'; // Importing flag kit
import PublicIcon from '@mui/icons-material/Public';
export const Switch = ({ onSelect }) => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [language, setLanguage] = React.useState(currentLanguage);

  const changeLanguage = (e) => {
    const selectedLanguage = e.target.value;
    setLanguage(selectedLanguage);
    i18n.changeLanguage(selectedLanguage);
    if (onSelect) onSelect(); // Ensure onSelect is called if defined
  };

  return (
    <FormControl style={{ minWidth: 70, marginRight: isMobile ? '5px' : '3%',}}>
      <Select
        value={language}
        onChange={changeLanguage}
        displayEmpty
        inputProps={{ 'aria-label': 'Language selector' }}
        style={{ padding: '0px', backgroundColor: 'black',boxShadow:"none",height:"30px"  }}
      >
        <MenuItem value="en" style={{}}>
          <Tooltip title="English">
            <div style={{ display: 'flex', alignItems: 'center', color:"grey" }}>
              {/* <FlagIcon code="US" size={24} style={{ marginRight: 8 }} /> EN */}
              <PublicIcon style={{ marginRight: 8, color:"#3ABEF9"  }} /> EN
           
            </div>
          </Tooltip>
        </MenuItem>
        <MenuItem value="am" style={{}}>
          <Tooltip title="አማርኛ">
            <div style={{ display: 'flex', alignItems: 'center', color:"grey" }}>
              <FlagIcon code="ET" size={24} style={{ marginRight: 8 }} /> አማ
            </div>
          </Tooltip>
        </MenuItem>
      </Select>
    </FormControl>
  );
};
