import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  Fade,
} from "@material-ui/core";
import { ChevronLeft, ChevronRight, InfoOutlined } from "@material-ui/icons";
import { images } from "../../../assets/index"; // Ensure this path is correct
import { BASE_FILE_URL } from "../../../constants/apiTags";
import { colorTags } from "../../../constants/variants";

const useStyles = makeStyles((theme) => ({
  carouselContainer: {
    position: "relative",
    width: "100%",
    height: "auto",
    minHeight: "220px",
    maxHeight: "240px",
    margin: "0 auto",
    overflow: "hidden",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      minHeight: "140px",
    },
  },
  carouselCard: {
    width: "100%",
    height: "100%",
    display: "flex",
    // backgroundColor: "rgba(255, 255, 0.3)",
    backgroundColor: `rgba(${colorTags.TERTIARY_RGBA}, 0.5)`,
    backgroundPosition: "center",
    flexDirection: "column",
    alignSelf: "center",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    position: "absolute",
    backgroundSize: "contain",
    top: 0,
    borderRadius: "10px",
    boxShadow: "none",
    cursor: "pointer",
  },
  adOverlay: {
    position: "absolute",
    top: 5,
    right: 5,
    display: "flex",
    alignItems: "center",
    backgroundColor: "rgba(255,255,255, 0.2)",
    color: "#000",
    padding: "2px 8px",
    borderRadius: "3px",
  },
  mainAd: {
    // position: "absolute",
    // top: 5,
    // right: 5,
    height: "100%",
    width: "100%",
    backdropFilter: "blur(10px)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    backgroundColor: "rgba(0,0,0, 0.1)",
    color: "#000",
    padding: "2px 8px",
    borderRadius: "8px",
  },
  sharpImage: {
    objectFit: "contain",
  },
  ad30: {
    width: "100%",
  },
  ad100: {
    width: "100%",
  },
  adCustom: {
    width: "50%",
  },
  arrowButton: {
    position: "absolute",
    top: 80,
    width: "60px",
    height: "60px",
    color: theme.palette.common.white,
    backgroundColor: "transparent",
    zIndex: 1,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  leftArrowButton: {
    left: 50,
    [theme.breakpoints.down(600)]: {
      left: 5,
      width: "40px",
      height: "40px",
    },
    [theme.breakpoints.down(900)]: {
      left: 20,
      width: "40px",
      height: "40px",
    },
  },
  rightArrowButton: {
    right: 50,
    [theme.breakpoints.down(900)]: {
      right: 20,
      width: "40px",
      height: "40px",
    },
    [theme.breakpoints.down(600)]: {
      right: 5,
      width: "40px",
      height: "40px",
    },
  },
}));

const ThinChevronLeft = withStyles((theme) => ({
  root: {
    fontSize: "20px",
    strokeWidth: "1.5",
  },
}))(ChevronLeft);

const ThinChevronRight = withStyles((theme) => ({
  root: {
    fontSize: "20px",
    strokeWidth: "1.5",
  },
}))(ChevronRight);

const Advertisements = ({ position, items }) => {
  const classes = useStyles();
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % items?.length);
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? items.length - 1 : prevIndex - 1
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  let adClass;
  switch (position) {
    case "sidebar":
      adClass = classes.ad30;
      break;
    case "header":
      adClass = classes.ad100;
      break;
    case "footer":
      adClass = classes.adCustom;
      break;
    default:
      adClass = classes.ad30;
  }

  return (
    <div className={`${classes.carouselContainer} ${adClass}`}>
      {position === "header" && (
        <IconButton
          className={`${classes.arrowButton} ${classes.leftArrowButton}`}
          onClick={handlePrevious}>
          <ThinChevronLeft />
        </IconButton>
      )}
      <div>
        {items?.map((item, index) => (
          <Fade
            key={index}
            in={index === currentIndex}
            timeout={{ enter: 1000, exit: 1000 }}>
            <div
              className={classes.carouselCard}
              style={{
                backgroundImage: `url(${BASE_FILE_URL}${item?.logo})`,
                // backgroundImage: `url(${item?.image})`,
              }}
              onClick={() => window.open(item.link ?? "")}>
              <div className={classes.mainAd}>
                <img
                  className={classes.sharpImage}
                  style={{ objectFit: "contain", height: "100%" }}
                  src={`${BASE_FILE_URL}${item?.logo}`}
                  alt="Advertisement"
                  onClick={() => window.open(item.link ?? "")}
                />
                <div className={classes.adOverlay}>
                  <InfoOutlined
                    style={{ fontSize: "16px", marginRight: "4px" }}
                  />
                  <Typography variant="body2">AD</Typography>
                </div>
              </div>
            </div>
          </Fade>
        ))}
      </div>
      {position === "header" && (
        <IconButton
          className={`${classes.arrowButton} ${classes.rightArrowButton}`}
          onClick={handleNext}>
          <ThinChevronRight />
        </IconButton>
      )}
    </div>
  );
};

export default Advertisements;
