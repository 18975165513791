import React, { useState } from "react";
import {
  Stack,
  styled,
  Link,
  Grid,
  Avatar,
  InputAdornment,
  FormControl,
  InputLabel,
  Button,
  MenuItem,
  Select,
  Fade,
  Divider,
  FormLabel,
  FormHelperText,
} from "@mui/material";
import {
  TextField,
  Typography,
  makeStyles,
  Container,
  Modal,
  Backdrop,
  TextareaAutosize,
} from "@material-ui/core";
import { colorTags } from "../../../../constants/variants";
import { useStyles } from "../../../../App";
import { DropzoneArea } from "material-ui-dropzone";
import {
  useUploadFileMutation,
  useCreateUserRequestMutation,
  useGetUserRolesQuery,
} from "../../../../features/api/apiSlice";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import { FILE_LINK_URL } from "../../../../constants/apiTags";

const useStyless = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalContent: {
    backgroundColor: "#fff",
    borderRadius: "10px",
    padding: "2% 2%",
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
    maxWidth: "40%",
    maxHeight: "90%",
    overflow: "auto",
    outline: "none",
  },
  modalTitle: {
    textAlign: "left",
    marginBottom: theme.spacing(2),
  },
  modalBody: {
    marginTop: theme.spacing(2),
  },
  inputField: {
    marginBottom: theme.spacing(2),
  },
  modalActions: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "flex-end",
  },
  textField: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#03AED2",
      },
      "&:hover fieldset": {
        borderColor: "#03AED2",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#03AED2",
      },
    },
    "& .MuiInputLabel-root": {
      color: "#03AED2",
    },
  },
}));

const AddUser = ({ open, onClose, title, onSave }) => {
  const [token, setToken] = useState(localStorage.getItem("authToken"));

  const classes = useStyless();
  const classez = useStyles();
  const [fullname, setFullname] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [status, setStatus] = useState("");
  const [fullnameError, setFullnameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [avatar, setAvatar] = useState([]);

  const [avatarError, setAvatarError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const roles = ["User", "Admin"];
  const statuses = ["Active", "Inactive"];
  const {
    data: rolesData,
    isLoading: isLoadingRolesData,
    isError: isErrorRolesData,
    error: errorRolesData,
  } = useGetUserRolesQuery();
  const [submitFileUpload] = useUploadFileMutation();
  const [
    createUserRequest,
    {
      isLoading: isCreateUserLoading,
      isError: isCreateUserError,
      error: createUserError,
    },
  ] = useCreateUserRequestMutation();
  const uploadImage = async (file) => {
    try {
      const formData = new FormData();
      formData.append("files", file);

      const response = await fetch(FILE_LINK_URL, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        return data;
      } else {
        throw new Error("Image upload failed");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      throw error;
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setFullnameError("");
    setEmailError("");
    setAvatarError("");

    const data = {
      fullName: fullname,
      phone: phone,
      email: email,
      user_name: email,

      role_id: role,
      status: status,
    };

    if (fullname.length === 0 || fullname.length > 100) {
      setFullnameError("Full name must be between 1 and 100 characters");
      setIsLoading(false);

      return;
    }

    if (!email.includes("@")) {
      setEmailError("Invalid email address");
      setIsLoading(false);

      return;
    }

    // if (avatar.length === 0) {
    //   setAvatarError("Please upload an avatar");
    //   setIsLoading(false)

    //   return;
    // }
    if (avatar) {
      try {
        const avatarResponse = await uploadImage(avatar[0]);
        data.avatar = avatarResponse?.filenames[0] ?? "";
      } catch (error) {
        toast.error("Error uploading avatar");
        data.avatar = "";
        setIsLoading(false);

        return;
      }
    }

    await createUserRequest(data)
      .then((res) => {
        toast.success("User Created Successfully");
      })
      .catch((err) => {
        toast.error("Error in creating user");
        setIsLoading(false);
      });

    setFullname("");
    setPhone("");
    setEmail("");
    setRole("");
    setStatus("");
    setAvatar([]);
    setIsLoading(false);
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      className={classes.modal}>
      <Fade in={open}>
        <div className={classes.modalContent}>
          <div className={classes.modalHead}>
            <Typography
              variant="h6"
              className={classes.modalTitle}
              style={{
                fontWeight: "medium",
                // color: "#03AED2",
                // color: "#000",
                color: colorTags.TERTIARY,

                borderRadius: "10px",
              }}>
              {title}
            </Typography>
          </div>
          <div className={classes.modalBody}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Full Name"
                  variant="outlined"
                  className={classes.textField}
                  style={{ borderRadius: "10px" }}
                  fullWidth
                  required
                  value={fullname}
                  onChange={(e) => setFullname(e.target.value)}
                  error={!!fullnameError}
                  helperText={fullnameError}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Phone"
                  variant="outlined"
                  className={classes.textField}
                  fullWidth
                  required
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Email"
                  variant="outlined"
                  className={classes.textField}
                  fullWidth
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  error={!!emailError}
                  helperText={emailError}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  fullWidth>
                  <InputLabel>Role</InputLabel>
                  <Select
                    value={role}
                    onChange={(e) => setRole(e.target.value)}
                    label="Role">
                    {/* {roles.map((role) => (
                      <MenuItem key={role} value={role}>
                        {role}
                      </MenuItem>
                    ))} */}
                    {rolesData?.map((role) => (
                      <MenuItem key={role.id} value={role.id}>
                        {role?.roleName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  fullWidth>
                  <InputLabel>Status</InputLabel>
                  <Select
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    label="Status">
                    {statuses.map((status) => (
                      <MenuItem key={status} value={status}>
                        {status}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <DropzoneArea
                  dropzoneClass={classez?.dropZone}
                  showAlerts={false}
                  useChipsForPreview={true}
                  acceptedFiles={[
                    "image/jpeg",
                    "image/jpg",
                    "image/png",
                    "image/webp",
                  ]}
                  filesLimit={1}
                  maxFiles={1}
                  maxFileSize={5000000}
                  dropzoneText={
                    "Drop or Click to Upload Avatar (JPEG, JPG, PNG, WEBP)"
                  }
                  onChange={(files) => setAvatar(files)}
                  error={!!avatarError}
                  helperText={avatarError}
                />
              </Grid>
            </Grid>
          </div>
          <div className={classes.modalActions}>
            <Stack spacing={2} direction="row">
              <div className={classes.modalActions}>
                <LoadingButton
                  // loading={isLoading}
                  variant="contained"
                  // color="success"
                  style={{
                    fontWeight: "bold",
                    color: "#fff",
                    borderRadius: "10px",
                    backgroundColor: colorTags.TERTIARY,
                  }}
                  onClick={handleSave}>
                  Save
                </LoadingButton>
                <Button
                  variant="outlined"
                  color="error"
                  style={{
                    color: "#FF0000",
                    marginLeft: "10px",
                    borderRadius: "15px",
                  }}
                  onClick={onClose}>
                  Cancel
                </Button>
              </div>
            </Stack>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default AddUser;
