import React, { useState, useEffect } from "react";
import {
  Paper,
  Typography,
  makeStyles,
  IconButton,
  Divider,
  Button, // Add Button for "Find out more"
} from "@material-ui/core";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import { images } from "../../../assets";
import { colorTags } from "../../../constants/variants";
import { useTranslation } from "react-i18next";
import { useGetEventsQuery } from "../../../features/api/apiSlice";
import { BASE_FILE_URL } from "../../../constants/apiTags";
import { Description } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(4),
    boxShadow: "none",
    position: "relative",
    overflow: "hidden",
    width: "100%",
  },
  eventCardContainer: {
    position: "relative",
    width: "100%",
    padding: theme.spacing(4),
    height: "500px",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
      height: "auto",
      display: "flex",
    },
  },
  eventCard: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    width: "100%",
    height: "95%",
    margin: "10px",
    padding: theme.spacing(0),
    overflow: "hidden",

    boxShadow:
      "rgb(204, 219, 232) 6px 6px 9px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      height: "auto",
    },
  },
  eventImage: {
    width: "70%",
    height: "100%",
    objectFit: "contain",
    backgroundPosition: "flex-start",
    marginRight: theme.spacing(2),
    boxShadow:
      "rgb(204, 219, 232) 6px 6px 9px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "200px",
      marginRight: 0,
      marginBottom: theme.spacing(2),
    },
  },
  eventContent: {
    width: "30%",
    display: "flex",
    flexDirection: "column",
    paddingLeft: "3%",
    paddingTop: "2%",
    justifyContent: "flex-start",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: 15,
    },
  },
  eventTitle: {
    fontWeight: "bold",
    marginBottom: theme.spacing(1),
    textAlign: "left",
    fontSize: "1.5rem",
    color: colorTags.PRIMARY,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem",
    },
  },
  eventDescription: {
    marginBottom: theme.spacing(1),
    textAlign: "left",
    fontSize: "1.2rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  },
  findOutMore: {
    color: theme.palette.primary.main,
    marginTop: theme.spacing(1),
    textTransform: "none",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.9rem",
    },
  },
  eventLocation: {
    marginBottom: theme.spacing(1),
    textAlign: "left",
    fontSize: "1.2rem",
    color: colorTags.TERTIARY,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  },
  eventDate: {
    color: theme.palette.text.secondary,
    textAlign: "left",
    fontSize: "1.2rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  },
  navigationButtons: {
    position: "absolute",
    bottom: theme.spacing(5),
    right: theme.spacing(3),
    zIndex: 1000,
    display: "flex",
    gap: theme.spacing(1),
  },
  arrowIcon: {
    fontSize: "2rem",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: "50%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
  },
}));

const Events = () => {
  const classes = useStyles();
  const [events, setEvents] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  // Fetch events data
  const { data: eventsData, isLoading, isError } = useGetEventsQuery();
  const truncateToWords = (text, maxLength) => {
    if (text?.length > maxLength) {
      return text?.substring(0, maxLength) + "...";
    }
    return text;
  };
  useEffect(() => {
    // Filter out events that have ended
    if (eventsData) {
      const filteredEvents = eventsData.filter(
        (event) => new Date(event.endDate) > new Date()
      );
      setEvents(filteredEvents);
    }
  }, [eventsData]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === events.length - 1 ? 0 : prevIndex + 1
      );
    }, 10000); // Auto switch interval
    return () => clearInterval(interval);
  }, [events.length]);

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === events.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? events.length - 1 : prevIndex - 1
    );
  };

  return (
    <Paper className={classes.root}>
      <Divider
        style={{ marginBottom: "10px", backgroundColor: colorTags.TERTIARY }}
      />
      <Typography variant="h6" className={classes.eventTitle}>
        {t("Events")}
      </Typography>
      <div className={classes.eventCardContainer}>
        <div className={classes.eventCard}>
          {events.length > 0 ? (
            <>
              <img
                src={`${BASE_FILE_URL}${events[currentIndex]?.coverImage}`}
                alt="Event"
                className={classes.eventImage}
              />
              <div className={classes.eventContent}>
                <Typography variant="h6" className={classes.eventTitle}>
                  {/* {truncateToWords(events[currentIndex]?.title, 150)} */}
                  {events[currentIndex]?.title}
                </Typography>
                <Typography
                  className={classes.eventDescription}
                  style={{ whiteSpace: "pre-line" }}>
                  {truncateToWords(events[currentIndex]?.description, 100)}
                </Typography>

                {/* Find out more button */}
                <Link to={`/events`} passHref>
                  <Button
                    className={classes.findOutMore}
                    variant="text"
                    style={{ color: colorTags.PRIMARY }}>
                    {t("Find out more")}
                  </Button>
                </Link>

                <Typography className={classes.eventLocation}>
                  {events[currentIndex]?.location}
                </Typography>
                <Typography className={classes.eventDate}>
                  {events[currentIndex]?.startDate &&
                  events[currentIndex]?.endDate
                    ? `${format(
                        new Date(events[currentIndex].startDate),
                        "MMMM dd, yyyy HH:mm"
                      )} - ${format(
                        new Date(events[currentIndex].endDate),
                        "MMMM dd, yyyy HH:mm"
                      )}`
                    : ""}
                </Typography>
              </div>
              <div className={classes.navigationButtons}>
                <IconButton onClick={handlePrev} className={classes.arrowIcon}>
                  <ArrowBackIos />
                </IconButton>
                <IconButton onClick={handleNext} className={classes.arrowIcon}>
                  <ArrowForwardIos />
                </IconButton>
              </div>
            </>
          ) : (
            <Typography variant="h6">No upcoming events</Typography>
          )}
        </div>
      </div>
    </Paper>
  );
};

export default Events;
