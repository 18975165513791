import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CardContent, Grid } from "@mui/material";
import { Typography, Divider, Card } from "@material-ui/core";
import { colorTags } from "../../../constants/variants";
import { BASE_FILE_URL } from "../../../constants/apiTags";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { images } from "../../../assets";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "left",
    width: "80%",
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      // padding: 15,
    },
  },
  categoryTitle: {
    marginTop: theme.spacing(3),
    color: colorTags.TERTIARY,
    fontSize: "2rem",
    fontWeight: "bold",
    "&:hover": {
      // color: "#007bff",
      color: colorTags.TERTIARY,
    },
  },
  mainCard: {
    width: "100%",
    margin: "20px auto",
    height: "auto",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    boxShadow: "none",
    cursor: "pointer",

    "&:hover": {
      // color: "#007bff",
      textColor: colorTags.TERTIARY,
    },
  },
  mainMedia: {
    width: "100%",
    height: "300px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
  },
  mainContent: {
    padding: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingLeft: "0px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  mainTitle: {
    fontSize: "2.8rem",
    fontWeight: "500",
    fontFamily: "Times-new-roman",
    marginBottom: theme.spacing(2),
    color: "#222",
    "&:hover": {
      // color: "#007bff",
      color: colorTags.TERTIARY,
    },
  },
  mainIntro: {
    fontSize: "1.1rem",
    lineHeight: 1.4,
    color: "#555",
  },
  overlay: {
    position: "absolute",
    bottom: "0px",
    left: "0px",
    color: "white",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    padding: "5px 10px",
    borderRadius: "0px",
  },
  divider: {
    marginTop: theme.spacing(1),
    width: "100%",
  },
  bigDivider: {
    marginTop: theme.spacing(1),
    width: "100%",
    backgroundColor: colorTags.TERTIARY,
    marginBottom: theme.spacing(3),
  },
  smallerStory: {
    marginTop: theme.spacing(3),
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    boxShadow: "none",
    cursor: "pointer",
  },
  smallerMedia: {
    width: "40%",
    // width: "120px",
    height: "100px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
  },
  smallerContent: {
    width: "60%",
    padding: theme.spacing(2),
    paddingTop: "0px",
    paddingLeft: "0px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    "&:hover": {
      // color: "#007bff",
      color: colorTags.TERTIARY,
    },
  },
  smallerTitle: {
    fontSize: "1rem",
    lineHeight: 1,
    fontFamily: "Times-new-roman",
    fontWeight: "bold",
    marginTop: "0px",
    paddingTop: "0px",
    marginBottom: theme.spacing(1),
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    color: "#222",
    "&:hover": {
      // color: "#007bff",
      color: colorTags.TERTIARY,
    },
  },
  smallerIntro: {
    fontSize: "0.8rem",
    lineHeight: 1.2,
    color: "#555",
  },
  dateOverlay: {
    position: "absolute",
    bottom: theme.spacing(0),
    left: theme.spacing(0),
    color: "white",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    padding: "5px 10px",
    borderRadius: "0px",
    fontSize: "0.8rem",
  },
}));
const truncateText = (text, maxLength) => {
  if (text?.length > maxLength) {
    return text?.substring(0, maxLength) + "...";
  }
  return text;
};
const CategoryNewsFull = ({ categoryTitle, articles }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const mainArticle = articles && articles[0];
  const smallerArticles = articles?.slice(1);
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  useEffect(() => {
    if (categoryTitle) console.log(categoryTitle);
  }, [categoryTitle]);

  return (
    <div className={classes.container}>
      <Typography className={classes.categoryTitle}>
        {/* {categoryTitle && "d"} */}
        {categoryTitle && t(categoryTitle)}
      </Typography>
      <Divider className={classes.bigDivider} />

      <Card
        className={classes.mainCard}
        style={{ boxShadow: "none" }}
        // onClick={() =>
        //   navigate(`/news-detail/${mainArticle && mainArticle?.id}`)
        // }
      >
        <Link to={`/news-detail/${mainArticle && mainArticle?.id}`}>
          <div
            className={classes.mainMedia}
            style={{
              backgroundImage:
                // `url(${BASE_FILE_URL}${mainArticle?.coverImage})`,
                // backgroundImage: `url(${mainArticle?.image})`,
                mainArticle?.coverImage !== null
                  ? `url(${BASE_FILE_URL}${mainArticle?.coverImage})`
                  : `url(${images.NEWS_PLACEHOLDER}`,
            }}>
            <div className={classes.overlay}>
              <Typography variant="div">
                {mainArticle && mainArticle?.imageCaption}
              </Typography>
            </div>
          </div>

          <CardContent
            className={classes.mainContent}
            style={{
              paddingLeft: "0px",
            }}>
            <Typography variant="h3" className={classes.mainTitle}>
              {truncateText(mainArticle && mainArticle.title, 100)}
            </Typography>
            <Typography variant="body1" className={classes.mainIntro}>
              {truncateText(mainArticle && mainArticle.title, 300)}
            </Typography>
          </CardContent>
        </Link>
      </Card>
      <Divider className={classes.divider} />
      <Grid container spacing={3}>
        {smallerArticles?.map((article, index) => (
          <Grid item xs={12} md={4} key={index}>
            <Card
              className={classes.smallerStory}
              onClick={() =>
                navigate(`/news-detail/${article && article?.id}`)
              }>
              <div
                className={classes.smallerMedia}
                style={{
                  //  backgroundImage: `url(${article.image})`
                  backgroundImage:
                    article?.coverImage !== null
                      ? `url(${BASE_FILE_URL}${article?.coverImage})`
                      : `url(${images.NEWS_PLACEHOLDER}`,
                }}>
                {/* <div className={classes.overlay}>
                  <Typography variant="div">{article?.imageCaption}</Typography>
                </div> */}
                <div className={classes.dateOverlay}>
                  <Typography variant="body2">{article?.date}</Typography>
                </div>
              </div>
              <CardContent
                className={classes.smallerContent}
                style={{
                  paddingTop: "0px",
                }}>
                <Link to={`/news-detail/${article && article?.id}`}>
                  <Typography
                    variant="h6"
                    className={classes.smallerTitle}
                    style={{
                      marginTop: "0px",
                    }}>
                    {truncateText(article?.title, 90)}
                  </Typography>
                  <Typography variant="body2" className={classes.smallerIntro}>
                    {truncateText(article?.intro, 50)}
                  </Typography>
                </Link>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default CategoryNewsFull;
