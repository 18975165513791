import React, { useState } from "react";
import {
  makeStyles,
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Button,
} from "@material-ui/core";
import { TextField } from "@mui/material";
import Header from "../Header/Header2";
import Footer from "../Footer/Footer2";
import { colorTags } from "../../../constants/variants";
import { LoadingButton } from "@mui/lab";
import { useContactUsMutation } from "../../../features/api/apiSlice";
import { toast } from "react-toastify";
import { images } from "../../../assets/index";
import LogoHead from "../Header/LogoHead2";
import Title from "../Header/Title2";
import Advertisements from "../NewsComponents2/Advertisements";
import { useGetAdsQuery } from "../../../features/api/apiSlice";

const useStyles = makeStyles((theme) => ({
  mainRoot: {
    position: "relative",
    overflow: "hidden",
    minHeight: "90vh",
  },
  root: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
    height: "60vh",
    display: "flex",
    alignItems: "center",
    position: "relative",
    zIndex: 1,
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      height: "auto",
    },
  },
  contactCard: {
    display: "flex",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
    borderRadius: "0px",
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 10px 50px",
  },
  cardMedia: {
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: 200,
    },
  },
  cardContent: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: theme.spacing(1),
    },
  },
  contactInfoOverlay: {
    position: "absolute",
    width: "50%",
    bottom: theme.spacing(0),
    left: theme.spacing(0),
    color: "#fff",
    backgroundColor: "rgba(0, 0, 0, 0.3)",
    padding: theme.spacing(2),
    backdropFilter: "blur(10px)",
    borderRadius: theme.spacing(0),
    zIndex: 2,
    [theme.breakpoints.down("sm")]: {
      // position: "static",
      width: "100%",
      padding: theme.spacing(4),

      bottom: theme.spacing(55),

      marginBottom: theme.spacing(2),
      backgroundColor: "transparent",
      color: "#000",
    },
  },
  submitButton: {
    marginTop: "25px",
    borderRadius: "15px",
    backgroundColor: "white",
    border: "0px solid grey",
    color: "#000",
    boxShadow: "rgba(0, 0, 0, 0.3) 0px 4px 5px 1px inset",
    "&:hover": {
      backgroundColor: "#ddd",
      boxShadow: "rgba(0,0,0, 0.3) 5px 6px 5px 1px inset",
      color: "#000",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "15px",
    },
  },
  contentForm: {
    padding: "10% 15%",
    [theme.breakpoints.down("sm")]: {
      padding: "5% 10%",
    },
  },
}));

const ContactUsPage = () => {
  const classes = useStyles();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [message, setMessage] = useState("");
  const {
    data: adsData,
    isLoading: isLoadingAds,
    isError: isErrorAds,
    error: adsError,
  } = useGetAdsQuery();

  const [contactUs, { isLoading: isLoadingContact }] = useContactUsMutation();

  const handleSubmit = async () => {
    const data = {
      fullName: firstName,
      email: emailAddress,
      message,
    };
    await contactUs(data)
      .then((res) => {
        if (res.data) {
          toast.success("Message sent successfully, Thank you!");
        }
      })
      .catch(() => {
        toast.error("Something went wrong, please try again later!");
      });
  };

  return (
    <div className={classes.mainRoot}>
      <Title />
      <LogoHead />
      <Header />

      <Container className={classes.root}>
        <Card className={classes.contactCard}>
          <CardMedia
            component="img"
            alt="Contact Background"
            image={images?.ContactUsPlaceholder}
            className={classes.cardMedia}
          />
          <div className={classes.contactInfoOverlay}>
            <Typography variant="body1" style={{ marginTop: "20px" }}>
              Email: media@addischamber.com
            </Typography>
            <Typography variant="body1" style={{ marginTop: "7px" }}>
              Phone: +251 115 573544
            </Typography>
            <Typography variant="body1" style={{ marginTop: "7p x" }}>
              Address: 2P5V+MQP, Addis Ababa, Ethiopia
            </Typography>
          </div>
          <CardContent className={classes.cardContent}>
            <form className={classes.contentForm}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label="Full Name"
                    variant="outlined"
                    color="success"
                    fullWidth
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Email"
                    variant="outlined"
                    color="success"
                    fullWidth
                    onChange={(e) => setEmailAddress(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Message"
                    variant="outlined"
                    color="success"
                    multiline
                    rows={4}
                    fullWidth
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </Grid>
              </Grid>
              <Button
                className={classes.submitButton}
                loading={isLoadingContact}
                variant="contained"
                size="large"
                onClick={handleSubmit}>
                Submit
              </Button>
            </form>
          </CardContent>
        </Card>
      </Container>

      {adsData && <Advertisements position="header" items={adsData} />}
      <Footer />
    </div>
  );
};

export default ContactUsPage;
